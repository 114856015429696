.delivery-tags {
  > * {
    margin-right: 0.5rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
}

.delivery-item-checkbox {
  align-self: center;
}

.delivery-item {
  display: flex;
  justify-content: space-between;
}

.delivery-section {
  margin-bottom: 5rem;
}

.delivery-tag-label-count {
  // width: 1rem;
  text-align: right;
  display: inline-block;
  margin-left: 0.3rem;
}

.delivery-select-all {
  margin-bottom: 1.5rem;
}

.delivery-filtering-section-wrap {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.delivery-filter-section {
  margin-bottom: 2rem;
  line-height: 3rem;
}

.delivery-filter {
  margin-right: 0.5rem;
}
