// @import '../node_modules/@ingka/page-container/_mixins.scss';
//
// $prefix: 'app-';
// @include page-container($prefix);

@import '../node_modules/@ingka/grid/_mixins.scss';
$prefix: 'my-prefix-';

.app-page-container {
  @include grid-container($small: 3, $medium: 9, $large: 12, $columnGap: true, $rowGap: true);

  > * {
    grid-column: 2 / 12;
  }
}

/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/
