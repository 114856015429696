// @import "../node_modules/@ingka/typography/style.scss";

@import '../node_modules/normalize.css';
@import '../node_modules/@ingka/core/style.scss';
@import '../node_modules/@ingka/typography/style.scss';
@import '../node_modules/@ingka/text/style.scss';
@import '../node_modules/@ingka/loading/style.scss';
@import '../node_modules/@ingka/list/style.scss';
@import '../node_modules/@ingka/focus/style.scss';
@import '../node_modules/@ingka/forms/style.scss';
@import '../node_modules/@ingka/pill/style.scss';
@import '../node_modules/@ingka/choice/style.scss';
@import '../node_modules/@ingka/button/style.scss';
@import '../node_modules/@ingka/tooltip/style.scss';
@import '../node_modules/@ingka/search/style.scss';
@import '../node_modules/@ingka/modal/style.scss';
@import '../node_modules/@ingka/inline-message/style.scss';
@import '../node_modules/@ingka/tabs/style.scss';
@import '../node_modules/@ingka/carousel/style.scss';
@import '../node_modules/@ingka/svg-icon/style.scss';
@import '../node_modules/@ingka/aspect-ratio-image/style.scss';
@import '../node_modules/@ingka/shoppable-image/style.scss';
@import '../node_modules/@ingka/accordion/style.scss';
@import '../node_modules/@ingka/image-info/style.scss';
@import '../node_modules/@ingka/skeleton/style.scss';
@import '../node_modules/@ingka/aspect-ratio-box/style.scss';
@import '../node_modules/@ingka/expander/style.scss';

@include typography();

/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include body($prefix);
}
    */

/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/

body {
  overflow-y: scroll;
}
