.row-items-wrap {
  display: flex;
  justify-content: center;
}

.row-item {
  margin-left: 5px;
  margin-right: 5px;
}

.filter-list-item {
  margin-right: 10px;
}

.hidden {
  visibility: hidden;
}

.tools-wrap {
  display: flex;
}

.tools-button {
  margin-right: 10px;
}
